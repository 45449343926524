import React from 'react'
import PostService from './API/PostService'
import ListCheck from './components/Icons/ListCheck'
import ListCross from './components/Icons/ListCross'
import ArrowOutcome from './components/Icons/ArrowOutcome'
import ArrowIncome from './components/Icons/ArrowIncome'

export default class Library {
  //------- ACTIVE TAB SCROLL POSITION -------//
  static activeTabScroll(mainState) {
    if (mainState.activeTabId) {
      const activeTab = document.getElementById(mainState.activeTabId)
      if (activeTab) {
        const container = activeTab.parentElement

        if (container) {
          const rect = activeTab.getBoundingClientRect()
          const containerRect = container.getBoundingClientRect()
          const scrollLeft = rect.left - containerRect.left + container.scrollLeft - container.clientWidth / 2 + rect.width / 2

          container.scrollLeft = scrollLeft
        }
      }
    }
  }

  //------- GET STATUS TYPE -------//
  static getStatusType(status) {
    if (status >= 100 && status < 200) {
      return 'info' // Informational
    } else if (status >= 200 && status < 300) {
      return 'success' // Successful
    } else if (status >= 300 && status < 400) {
      return 'redirect' // Redirection
    } else if (status >= 400 && status < 500) {
      return 'warn' // Client Errors
    } else if (status >= 500) {
      return 'error' // Server Errors
    } else {
      return 'unknown' // For unhandled or non-standard status codes
    }
  }

  //------- HANDLE RESPONSE STATUS -------//
  static handleResponseStatus(response, message) {
    const { status } = response

    if (response.data.errors) {
      response.data.errors.forEach((err) => {
        message(err.msg, 'error')
      })
      return
    }

    switch (status) {
      case 200:
      case 201:
        // Successfully
        message(response.data.message, 'success')
        break
      case 400: // Bad request
        message(response.data.message, 'error')
        break
      case 403: // Forbidden
        message(response.data.message, 'error')
        break
      case 404: // Not found
        message(response.data.message, 'error')
        break
      case 409: // Conflict
        message(response.data.message, 'warn')
        break
      case 429: // Too Many Requests
        message(response.data.message, 'warn')
        break
      case 500:
        // Internal Server Error
        message(response.data.message, 'error')
        break
      default:
        // Unknown status code
        message('An unknown error occurred. Please try again.', 'error')
    }
  }

  //------- THROTTLE -------//
  static throttle(func, delay) {
    let lastCall = 0
    return function (...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return func(...args)
    }
  }

  //------- SORT -------//
  static sort(data, sort) {
    data.sort((a, b) => {
      const key = sort.key
      let valueA = a[key]
      let valueB = b[key]

      switch (sort.dataType) {
        case 'date':
          valueA = new Date(valueA)
          valueB = new Date(valueB)
          break
        case 'number':
          valueA = Number(valueA)
          valueB = Number(valueB)
          break
        case 'string':
          valueA = String(valueA)
          valueB = String(valueB)
          break
        default:
          break
      }

      // Compare values
      if (valueA < valueB) {
        return sort.direction ? -1 : 1
      }
      if (valueA > valueB) {
        return sort.direction ? 1 : -1
      }
      return 0 // values are equal
    })
  }

  //------- HIGHLIGHT -------//
  static highlight = (chunk, query) => {
    if (!query || !chunk) return chunk
    if (chunk.toLowerCase().includes(query.toLowerCase())) {
      return chunk.split(new RegExp(`(${query})`, 'gi')).map((part, index) => {
        return part.toLowerCase() === query.toLowerCase() ? (
          <mark key={index} style={{ background: '#2769AA', color: 'white' }}>
            {part}
          </mark>
        ) : (
          <React.Fragment key={index}>{part}</React.Fragment>
        )
      })
    } else if (
      (/^\d+\.\d+$|^\d+$/.test(query) &&
        chunk.includes(parseFloat(query).toLocaleString())) ||
      parseFloat(chunk).toLocaleString().includes(query)
    ) {
      return chunk
        .split(new RegExp(`(${parseFloat(query).toLocaleString()})`, 'gi'))
        .map((part, index) => {
          return part === parseFloat(query).toLocaleString() ? (
            <mark key={index} style={{ background: '#2769AA', color: 'white' }}>
              {part}
            </mark>
          ) : (
            <React.Fragment key={index}>{part}</React.Fragment>
          )
        })
    } else {
      return chunk
    }
  }

  //------- FORMAT CELL VALUE -------//
  static formatCellValue = (item, header, index, filterQuery, isActive, isHovered) => {
    const key = header.id
    const type = header.type
    switch (type) {
      case 'timestamp': {
        const value = this.formatDate(item[key], 'DD.MM.yyyy HH:mm:ss')
        return [this.highlight(value, filterQuery.search), value]
      }
      case 'status': {
        switch (item[key]) {
          case 'markedForDeletion': {
            return [<div style={{ height: '30px' }}><ListCross size={28} isActive={isActive} isHovered={isHovered} /></div>, 'Помечен на удаление']
          }
          default: {
            return [<div style={{ height: '30px' }}><ListCheck size={28} isActive={isActive} isHovered={isHovered} /></div>, 'Активный']
          }
        }
      }
      case 'direction': {
        switch (item[key]) {
          case 'outgoing': {
            return [<div style={{ height: '19px' }}><ArrowOutcome size={18} isActive={isActive} isHovered={isHovered} /></div>, 'Расход']
          }
          default: {
            return [<div style={{ height: '19px' }}><ArrowIncome size={18} isActive={isActive} isHovered={isHovered} /></div>, 'Приход']
          }
        }
      }
      case 'date': {
        const value = this.formatDate(item[key], 'DD.MM.yyyy')
        return [this.highlight(value, filterQuery.search), value]
      }
      case 'check-mark': {
        const value = item[key] ? '☑' : ''
        return [this.highlight(value, filterQuery.search), value]
      }
      case 'increment': {
        const value = `${index + 1}`
        return [this.highlight(value, filterQuery.search), value]
      }
      case 'url': {
        const url = this.checkUrl(item[key])
        return [item[key] ?
          <a href={url} target='_blank' rel='noopener noreferrer'>{this.highlight(url, filterQuery.search)}</a> :
          '', url]
      }
      default: {
        const value =
          typeof item[key] === 'number'
            ? parseFloat(item[key]).toLocaleString()
            : item[key]
        return [this.highlight(value, filterQuery.search), value]
      }
    }
  }

  //------- FIND NAME BY KEY -------//
  static findNameByKey(arr, id) {
    const foundItem = arr.find(item => item.id === id)
    return foundItem && foundItem.name ? foundItem.name : null
  }

  //------- FIND ITEM BY ID -------//
  static findItemById = (array, id) => {
    return array.find(item => item.id === id)
  }

  //------- TRUNCATE -------//
  static truncate(str, n) {
    if (typeof str === 'string' && str.length > n) {
      return str.slice(0, n - 1) + '...'
    }
    return str
  }

  //------- REPLACE PERIOD -------//
  static replacePeriod(str) {
    const months = { '01': 'Январь', '02': 'Февраль', '03': 'Март', '04': 'Апрель', '05': 'Май', '06': 'Июнь', '07': 'Июль', '08': 'Август', '09': 'Сентябрь', '10': 'Октябрь', '11': 'Ноябрь', '12': 'Декабрь' }
    const currMonth = str.slice(-2)
    const currYear = str.slice(0, 4)
    return `${months[currMonth]} ${currYear}`
  }

  //------- FORMAT DATE -------//
  static formatDate(date, format) {
    if (typeof date === 'undefined' || date === '' || date === null) {
      return ''
    }

    let parsedDate = new Date(date)

    if (isNaN(parsedDate)) {
      parsedDate = new Date(date.replace(/-/g, '/').replace('T', ' ').replace('Z', ''))
    }

    if (isNaN(parsedDate)) {
      return ''
    }

    switch (format) {
      case 'DD.MM.yyyy': {
        return new Intl.DateTimeFormat('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(parsedDate)
      }
      case 'yyyy-MM-dd': {
        return `${parsedDate.getFullYear()}-${('0' + (parsedDate.getMonth() + 1)).slice(-2)}-${('0' + parsedDate.getDate()).slice(-2)}`
      }
      case 'yyyy.MM.DD HH:mm:ss':
        return `${parsedDate.getFullYear()}.${('0' + (parsedDate.getMonth() + 1)).slice(-2)}.${('0' + parsedDate.getDate()).slice(-2)} ${('0' + parsedDate.getHours()).slice(-2)}:${('0' + parsedDate.getMinutes()).slice(-2)}:${('0' + parsedDate.getSeconds()).slice(-2)}`
      case 'DD.MM.yyyy HH:mm:ss':
        return `${('0' + parsedDate.getDate()).slice(-2)}.${('0' + (parsedDate.getMonth() + 1)).slice(-2)}.${parsedDate.getFullYear()} ${('0' + parsedDate.getHours()).slice(-2)}:${('0' + parsedDate.getMinutes()).slice(-2)}:${('0' + parsedDate.getSeconds()).slice(-2)}`
      default: {
        return new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        }).format(parsedDate).replace(/\//g, '.').split('.').map(str => str.padStart(2, '0')).join('.')
      }
    }
  }

  //------- FORMAT RECENT DATE -------//
  static formatRecentDate = (date) => {
    const today = new Date()
    const compareDate = new Date(date)

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    if (
      compareDate.getDate() === today.getDate() &&
      compareDate.getMonth() === today.getMonth() &&
      compareDate.getFullYear() === today.getFullYear()
    ) {
      return `Сегодня ${compareDate.getHours().toString().padStart(2, '0')}:${compareDate.getMinutes().toString().padStart(2, '0')}:${compareDate.getSeconds().toString().padStart(2, '0')}`
    } else if (
      compareDate.getDate() === yesterday.getDate() &&
      compareDate.getMonth() === yesterday.getMonth() &&
      compareDate.getFullYear() === yesterday.getFullYear()
    ) {
      return `Вчера ${compareDate.getHours().toString().padStart(2, '0')}:${compareDate.getMinutes().toString().padStart(2, '0')}:${compareDate.getSeconds().toString().padStart(2, '0')}`
    }

    return this.formatDate(date, 'DD.MM.yyyy HH:mm:ss')
  }

  //------- FORMAT TIMESTAMP -------//
  static formatTimestamp(date, format) {
    switch (format) {
      case 'yyyy.MM.DD HH:mm:ss':
        date = new Date(date)
        return `${date.getFullYear()}.${('0' + (date.getMonth() + 1)).slice(-2)}.${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`
      default: {
        return `${date.getFullYear()}.${('0' + (date.getMonth() + 1)).slice(-2)}.${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`
      }
    }
  }

  //------- CHECK URL -------//
  static checkUrl = (url) => {
    const pattern = new RegExp('^(http|https)://', 'i')
    if (!pattern.test(url)) {
      url = `http://${url}`
    }
    return url
  }

  //------- CHANGE HANDLER -------//
  static changeHandler = (event, form, setForm, setChangedField) => {
    const fieldName = event.target.name
    switch (event.target.type) {
      case 'checkbox': {
        setForm({ ...form, [fieldName]: event.target.checked ? 1 : 0 })
        break
      }
      case 'number': {
        setForm({ ...form, [fieldName]: event.target.value ? parseFloat(event.target.value) : null })
        break
      }
      default: {
        setForm({ ...form, [fieldName]: event.target.value ? event.target.value : '' })
      }
    }

    // Set the flag for the specific field change
    typeof setChangedField === 'function' &&
      setChangedField(fieldName)
  }

  //-------------- HANDLER --------------//
  static handler = async (params) => {
    const { e, path, request, form, message, edit, onSuccess, onFail, language = 'ru' } = params
    e.preventDefault()
    try {
      const response = edit
        ? await PostService.patch(path, request, form, language)
        : await PostService.put(path, request, form, language)
      // successful responses
      if (response.status >= 200 && response.status < 300) {
        if (response.data) {
          typeof message === 'function' && message(response.data.message || 'Данные изменены', 'success')
          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } else {
          typeof message === 'function' && message(response.message || 'Данные сохранены', 'success')
          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        }
      }
      // failed request
      else if (response.status === 400) {
        if (response.data.errors) {
          response.data.errors.forEach((err) => {
            typeof message === 'function' && message(err.msg, 'error')
          })
        } else {
          typeof message === 'function' && message(response.data.message || 'Ваш запрос неверен или неполный', 'error')
        }
        if (typeof onFail === 'function') {
          onFail()
        }
      }
      // request not found
      else if (response.status === 404) {
        typeof message === 'function' && message(response.data.message || 'Запрошенный ресурс не найден', 'error')
        if (typeof onFail === 'function') {
          onFail()
        }
      }
      // conflict request
      else if (response.status === 409) {
        typeof message === 'function' && message(response.data.message || 'Конфликт. Запрос не может быть выполнен из-за конфликта с текущим состоянием целевого ресурса.', 'warn')
        if (typeof onFail === 'function') {
          onFail()
        }
      } else {
        console.log(`Error: ${response.status} - ${response.statusText}`)
        typeof message === 'function' && message(response.data.message || 'Что-то пошло не так, попробуйте снова', 'warn')
        if (typeof onFail === 'function') {
          onFail()
        }
      }
    } catch (error) {
      typeof message === 'function' && message(`Error: ${error.message}`, 'error')
      if (typeof onFail === 'function') {
        onFail()
      }
    }
  }

  //-------------- IS MOBILE --------------//
  static isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  //-------------- BROWSER FILE EXTENSIONS --------------//
  static browserFileExtensions = [
    // Document Formats
    'html', // HyperText Markup Language, for displaying web pages
    'htm',  // Similar to .html but with a shorter extension
    'xml',  // eXtensible Markup Language
    'json', // JavaScript Object Notation, often used for data interchange
    'txt',  // Plain text file
    'pdf',  // Portable Document Format

    // Image Formats
    'svg',  // Scalable Vector Graphics, for vector images
    'jpg',  // Joint Photographic Experts Group, for raster images
    'jpeg', // Joint Photographic Experts Group, for raster images
    'png',  // Portable Network Graphics, for raster images
    'gif',  // Graphics Interchange Format, for animated images
    'bmp',  // Bitmap, for raster images
    'webp', // WebP image format by Google
    'jfif', // Image file format

    // Style and Script Formats
    'css',  // Cascading Style Sheets, for styling HTML documents
    'js',   // JavaScript files

    // Audio Formats
    'mp3',  // MPEG Audio Stream
    'wav',  // Waveform Audio File Format
    'ogg',  // Ogg Vorbis audio

    // Video Formats
    'mp4',  // MPEG-4 Part 14
    'webm', // WebM format
    'ogv'   // Ogg Video
  ]

}
